import { get, post, put, Delete } from "../axios";

// 会员徽章-列表
export const memberBadgeList = (params) =>
  get("/web/member/badge/list", params);

// 会员徽章-新增
export const memberBadgeAdd = (params) => post("/web/member/badge/add", params);

// 会员徽章-编辑
export const memberBadgeUpdate = (params) =>
  put("/web/member/badge/update", params);

// 会员徽章-删除
export const memberBadgeDelete = (id) =>
  Delete("/web/member/badge/delete/" + id, "");
