<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >徽章名称 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.name"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleSearch"
                >搜索</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="info" @click="handleReset"
                >重置</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleCreate"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <!-- <template slot="title" slot-scope="{ option }">
        <div class="custom-title">
          <span>自定义标题</span>
          <span class="total">总数：{{ option.total }}</span>
        </div>
      </template> -->
      <template slot="icon" slot-scope="{ row }">
        <el-image
          style="width: 80px; height: 80px"
          :src="row.icon"
          :preview-src-list="[row.icon]"
        >
        </el-image>
      </template>
      <template slot="state" slot-scope="{ row }">
        <el-switch
          :value="row.state"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event, row.id)"
        ></el-switch>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
          style="color: #ff001f !important"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? '修改会员徽章' : '新增会员徽章'}`"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      style="padding-right: 10%"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="name">
          <el-input
            v-model="form.name"
            maxlength="20"
            placeholder="请输入"
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'name')"
            type="text"
            clearable
            :disabled="isDetails"
          />
        </template>
        <template slot="icon">
          <FormImgUpload
            :url="form.icon"
            @upload="upload"
            :limit="1"
            :maxSize="20"
            :accept="'.png,.jpg'"
            :multiple="true"
          />
          <div style="color: #c0c1c3">
            建议尺寸：600px*748px，图片格式：.png，.jpg
          </div>
        </template>

        <template slot="menuForm">
          <el-button @click="handleClose">
            {{ isDetails ? "关闭" : "取消" }}</el-button
          >
          <el-button
            type="primary"
            @click="submit(form)"
            :loading="showLoadingForm"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  memberBadgeList,
  memberBadgeAdd,
  memberBadgeUpdate,
  memberBadgeDelete,
} from "@/api/user/badge";
import { mapGetters } from "vuex";

import FormImgUpload from "@/components/formComponents/formImgUpload";

export default {
  name: "index",
  components: {
    FormImgUpload,
  },
  // computed: {
  // 	...mapGetters(['getButton'])
  // },
  data() {
    return {
      button: {},
      showAuthority: true,
      isDetails: false,
      typeList: [],
      entryList: [],
      searchData: {
        name: "",
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      tableData: [], //列表数据
      showLoading: false,
      showLoadingForm: false, //防重提交
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "徽章名称",
            prop: "name",
          },
          {
            label: "徽章图标",
            prop: "icon",
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
        ],
      },

      dialogVisible: false,
      form: {
        icon: [],
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "120",
        row: true,
        column: [
          {
            label: "徽章图标",
            prop: "icon",
            type: "text",
            maxlength: 50,
            span: 20,
            rules: [
              {
                required: true,
                message: "请上传",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "徽章名称",
            prop: "name",
            type: "text",
            maxlength: 20,
            placeholder: "请输入",
            span: 20,
            rules: [
              {
                required: true,
                message: "请输入导航名称",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      dialogPassWordVisible: false,
      dataUrl: "",
    };
  },
  created() {},
  methods: {
    //初始化
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      this.showLoading = true;
      memberBadgeList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.icon = [...e];
      } else {
        this.form.icon = [...this.form.icon, e];
      }
    },
    // 添加
    handleCreate() {
      this.form.image = [];
      this.isDetails = false;
      this.form.id = "";
      this.dialogVisible = true;
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      // localStorage.removeItem("searchForm");
      this.searchData = {
        title: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    //编辑
    async handleEdit(row) {
      // console.log(row)
      this.isDetails = false;
      this.form = { ...row, icon: [row.icon] };
      this.form.id = row.id;
      this.dialogVisible = true;
    },

    // 删除
    handleDelete(row) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        memberBadgeDelete(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          }
        });
      });
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
      };
    },

    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          // return
          if (form.id) {
            memberBadgeUpdate({
              ...form,
              // image: form.image[0]
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.showLoadingForm = false;
                  this.onLoad();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          } else {
            memberBadgeAdd({
              ...form,
              image: form.image[0],
              url: form.url || "",
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.showLoadingForm = false;
                  this.onLoad();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          }
          // this.form.type = 1;
          // done()
        }
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },

    handlePassWordClose() {
      this.dialogPassWordVisible = false;
    },
    //切换是否显示
    handleSwitch(e, id) {
      pcStateUpdate({
        id: id,
        state: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        }
        this.onLoad();
      });
    },
  },
};
</script>

<style scoped>
.copy {
  cursor: pointer;
  /* width: 60px; */
  margin-left: 15px;
  color: #66b1ff;
}
.tips {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 30px;
}
</style>
